import React from "react"
import Slider from "react-slick"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "./styles.scss"
import GridTwo from "../components/grid-2"
import { StyleSheet, css } from "aphrodite"
import { graphql, Link } from "gatsby"

const IndexPage = ({ data: { allWpPost } }) => {
	const tabsInfo = [
		{
			tag: "FMCG",
			name: "Alliance World Manufacturing Ltd.",
			desc: "Products for leading multinationals",
			left: `
      <div style="display:flex;flex-direction:column;justify-content:space-between;align-items:flex-start; height:100%;padding:2rem;">
      <p class="mob-margin-bottom">Alliance World Manufacturing Ltd. (“f.k.a. - Alliance India”) is the FMCG arm of Alliance Group, organized into manufacturing customized products for leading multinationals. <br/> <br/>
      AWML has added 2 new Subsidiaries for setting up two large projects : <br/>

> Alliance India Consumer Products Pvt. Ltd. (AICPPL) <br/>
> PT Alliance Consumer Products Indonesia (PTACPI)

      </p>
      <a href="/companies#alliance-india" class="button is-outlined is-primary is-size-5 is-uppercase has-text-weight-light">Know More</a>
      </div>
      `,
			right: `<img src="${"/companies/alliance-india.jpg"}" style="width:100%;" />`,
		},
		{
			tag: "Pharmaceuticals",
			name: "Alliance Formulations",
			desc: "Manufacturing antiseptic-alcohol based liquids, ointments & cosmetics",
			left: `
      <div style="display:flex;flex-direction:column;justify-content:space-between;align-items:flex-start; height:100%;padding:2rem;">
      <p class="mob-margin-bottom">Alliance Formulations is the pharmaceuticals wing of
      Alliance World. Alliance Formulation has been
      associated with a wide spectrum of pharmaceutical
      products from antiseptic-alcohol based formulations,
      liquids, ointments to cosmetics.</p>
      <a href="/companies#alliance-formulations" class="button is-outlined is-primary is-size-5 is-uppercase has-text-weight-light">Know More</a>
      </div>
      `,
			right: `<img src="${"/companies/alliance-formulations.jpg"}" style="width:100%;" />`,
		},
		{
			tag: "Agriculture",
			name: "Alliance Agri-Tech",
			desc: "Seed production, processing, storage & monitoring",
			left: `
      <div style="display:flex;flex-direction:column;justify-content:space-between;align-items:flex-start; height:100%;padding:2rem;">
      <p class="mob-margin-bottom">Alliance Agri-tech has many years of experience in the
      Agriculture Industry - seeds. Our customers include a
      number of leading agriculture companies including Rice
      Tech (Savannah seeds), Syngenta, Mahyco, US Agri.</p>
      <a href="/companies#alliance-agri-tech" class="button is-outlined is-primary is-size-5 is-uppercase has-text-weight-light">Know More</a>
      </div>
      `,
			right: `<img src="${"/companies/alliance-agri-tech.jpg"}" style="width:100%;" />`,
		},
		{
			tag: "Engineering Tech",
			name: "ENVAIR Electrodyne Ltd.",
			desc: "Manufacturing air purifiers, oil filters & technical furniture",
			left: `
      <div style="display:flex;flex-direction:column;justify-content:space-between;align-items:flex-start; height:100%;padding:2rem;">
      <p class="mob-margin-bottom"><i>(Formerly known as Kirloskar Electrodyne Ltd. Till 2002) was
      part of the multi-crore Kirloskar Group of Companies.</i><br/><br/>
      Envair Electrodyne Limited powered by innovative
      and quality attitudes, state-of-the-art infrastructure,
      skilled work force and R & D facilities has indeed
      carved a solid niche for itself.</p>
      <a href="/companies#envair-electrodyne" class="button is-outlined is-primary is-size-5 is-uppercase has-text-weight-light">Know More</a>
      </div>
      `,
			right: `<img src="${"/companies/envair-electrodyne.jpg"}" style="width:100%;" />`,
		},
		{
			tag: "Farming",
			name: "Alliance Farms",
			desc: "Farming & processing of dry fruits",
			left: `
      <div style="display:flex;flex-direction:column;justify-content:space-between;align-items:flex-start; height:100%;padding:2rem;">
      <p class="mob-margin-bottom">Alliance World Farms have developed plantations in
      California, USA – for farming of nutrients like
      almonds and walnuts along with dry fruit
      processing units.</p>
      <a href="/companies#alliance-farms" class="button is-outlined is-primary is-size-5 is-uppercase has-text-weight-light">Know More</a>
      </div>
      `,
			right: `<img src="${"/companies/alliance-farms.jpg"}" style="width:100%;" />`,
		},
		{
			tag: "Marketing",
			name: "Imperial Marketing Services",
			desc: "Formulating marketing strategies, logistics solutions across businesses",
			left: `
      <div style="display:flex;flex-direction:column;justify-content:space-between;align-items:flex-start; height:100%;padding:2rem;">
      <p class="mob-margin-bottom">Imperial Marketing Services Pvt Ltd (IMS), an undertaking of Alliance World, is associated with formulating marketing strategies across business domains. <br/><br/>Backed with a team of charged sales and marketing personnel, IMS specializes in analyzing marketing trends and formulating competitive strategies to outdo its competitors.</p>
      <a href="/companies#imperial-marketing-services" class="button is-outlined is-primary is-size-5 is-uppercase has-text-weight-light">Know More</a>
      </div>
      `,
			right: `<img src="${"/companies/imperial-marketing-services.jpg"}" style="width:100%;" />`,
		},
	]

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 1000,
		pauseOnHover: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	}

	const settings2 = {
		dots: false,
		fade: true,
		infinite: true,
		speed: 1800,
		autoplay: true,
		autoplaySpeed: 3200,
		pauseOnHover: false,
		slidesToShow: 1,
		slidesToScroll: 1,
	}

	return (
		<Layout>
			<SEO title='Home' />
			{/* Hero Area */}
			<section
				className='hero is-fullheight has-background-image is-relative'
				style={{ overflow: "hidden" }}
			>
				<div
					className='stretched'
					style={{
						width: "100%",
						height: "100%",
						position: "absolute",
						left: "0",
						top: 0,
					}}
				>
					<Slider {...settings2}>
						<div>
							<img
								src={"/images/1.jpg"}
								style={{
									width: "100%",
									height: "100%",
									objectFit: "cover",
									objectPosition: "center right",
								}}
							/>
						</div>
						<div>
							<img
								src={"/images/slider/2.jpg"}
								style={{
									width: "100%",
									height: "100%",
									objectFit: "cover",
									objectPosition: "center right",
								}}
							/>
						</div>
						<div>
							<img
								src={"/images/slider/3.jpg"}
								style={{
									width: "100%",
									height: "100%",
									objectFit: "cover",
									objectPosition: "center right",
								}}
							/>
						</div>
						<div>
							<img
								src={"/images/slider/4.jpg"}
								style={{
									width: "100%",
									height: "100%",
									objectFit: "cover",
									objectPosition: "center right",
								}}
							/>
						</div>
					</Slider>
				</div>
				<div className='hero-body'>
					<div className='container'>
						<div className='columns'>
							<div className='column is-8 is-offset-4'>
								<h1
									className='title has-text-primary is-size-0 is-size-1-mobile'
									data-sal='slide-right'
									data-sal-duration='800'
									data-sal-delay='300'
									data-sal-easing='ease'
								>
									Partnering excellence
								</h1>
								<p
									className='subtitle has-text-primary is-size-3 is-size-4-mobile'
									data-sal='slide-right'
									data-sal-duration='800'
									data-sal-delay='500'
									data-sal-easing='ease'
								>
									Specializing in supply chain, contract manufacturing and need
									based services for Pharmaceuticals, FMCG’s, Agriculture, Logistics & Engineering Tech.
								</p>
							</div>
						</div>
					</div>
					<div
						style={{
							position: "absolute",
							bottom: "20px",
							left: "0",
							width: "100%",
						}}
					>
						<div className='container'>
							<div className='columns is-mobile'>
								<div className='column is-8-tablet is-offset-4-tablet is-11-mobile is-offset-1-mobile'>
									<p className='is-size-5'>
										Get in touch info@alliance-world.com
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* News & Events Section */}
			<section className='section'>
				<div className='container'>
					<div className='grid'>
						<div
							className='has-background-light mobile-bg-hidden'
							data-sal='slide-right'
							data-sal-duration='800'
							data-sal-delay='300'
							data-sal-easing='ease'
						>
							<h2 className='has-text-primary is-size-2 has-text-weight-bold'>
								Inside Alliance
							</h2>
							<p className='is-size-3 has-text-weight-bold'>News & Events</p>
						</div>
						<div
							data-sal='slide-right'
							data-sal-duration='800'
							data-sal-delay='300'
							data-sal-easing='ease'
							className='has-background-image has-text-primary  is-relative'
							style={{
								minHeight: "45vh",
								backgroundSize: "contain",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundColor: "#fff",
							}}
						>
							<iframe
								width='100%'
								height='100%'
								src='https://www.youtube-nocookie.com/embed/CPsy7r3J918?controls=0'
								title='YouTube video player'
								frameborder='0'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowfullscreen
							></iframe>
						</div>
						{allWpPost.nodes &&
							allWpPost.nodes.map((post) => (
								<Link to={`/news/${post.slug}`}>
									<img
										src={post.featuredImage.node.mediaItemUrl}
										style={{
											width: "100%",
											marginBottom: "1rem",
											aspectRatio: "3/2",
											objectFit: "cover",
										}}
										alt=''
									/>
									<h5 className='is-size-5 has-text-black has-text-weight-normal'>
										{post.title}
									</h5>
									<span style={{ color: "#999" }}>{post.date}</span>
									<br />
									<br />
									<span className='is-size-6 has-text-primary'>
										Read More >>
									</span>
								</Link>
							))}
					</div>
				</div>
			</section>

			{/* Alliance at a glance - blue section */}
			<section
				data-sal='fade'
				data-sal-duration='600'
				data-sal-delay='0'
				data-sal-easing='ease'
				className='section is-medium has-background-primary has-text-white has-text-centered'
			>
				<div className='container'>
					<h2
						data-sal='slide-right'
						data-sal-duration='800'
						data-sal-delay='300'
						data-sal-easing='ease'
						className='is-size-2 has-text-weight-bold'
					>
						Alliance At A Glance
					</h2>
					<br />
					<br />
					<p
						data-sal='slide-right'
						data-sal-duration='800'
						data-sal-delay='300'
						data-sal-easing='ease'
						className='is-size-4 w-80'
						style={{ margin: "0 auto" }}
					>
						Our journey started in 1929 with ICI Plc. UK. Today, Alliance World
						has grown to a multi-fold group company with multi-million
						turonover.
					</p>
					<br />
					<br />
					<br />
					<div className='columns is-variable is-8'>
						<div
							data-sal='slide-right'
							data-sal-duration='800'
							data-sal-delay='200'
							data-sal-easing='ease'
							className='column is-4 mob-margin-bottom'
						>
							<img
								src={"/icons/exp.png"}
								style={{
									height: "42px",
									objectFit: "contain",
									margin: "0 auto 2rem",
								}}
							></img>

							<h2 className='is-size-2 has-text-weight-bold'> 90 Years</h2>
							<p className='is-size-4 has-text-weight-light'>of experience</p>
							<hr
								className='has-background-white'
								style={{ width: "40%", margin: "2rem auto", height: "1px" }}
							/>
							<Link
								to='/about'
								style={{ width: "80%" }}
								className='is-size-5 button is-outlined is-primary is-inverted is-uppercase'
							>
								About us
							</Link>
						</div>
						<div
							data-sal='slide-right'
							data-sal-duration='800'
							data-sal-delay='300'
							data-sal-easing='ease'
							className='column is-4 mob-margin-bottom'
						>
							<img
								src={"/icons/turnover.png"}
								style={{
									height: "42px",
									objectFit: "contain",
									margin: "0 auto 2rem",
								}}
							></img>
							<br />
							<h2 className='is-size-2 has-text-weight-bold'> $270 million</h2>
							<p className='is-size-4 has-text-weight-light'>annual turnover</p>
							<hr
								className='has-background-white'
								style={{ width: "40%", margin: "2rem auto", height: "1px" }}
							/>
							<Link
								to='/companies'
								style={{ width: "80%" }}
								className='is-size-5 button is-outlined is-primary is-inverted is-uppercase'
							>
								GROUP COMPANIES
							</Link>
						</div>
						<div
							data-sal='slide-right'
							data-sal-duration='800'
							data-sal-delay='400'
							data-sal-easing='ease'
							className='column is-4'
						>
							<img
								src={"/icons/work.png"}
								style={{
									height: "42px",
									objectFit: "contain",
									margin: "0 auto 2rem",
								}}
							></img>
							<br />
							<h2 className='is-size-2 has-text-weight-bold'> 2100</h2>
							<p className='is-size-4 has-text-weight-light'>total workforce</p>
							<hr
								className='has-background-white'
								style={{ width: "40%", margin: "2rem auto", height: "1px" }}
							/>
							<Link
								to='/contact'
								style={{ width: "80%" }}
								className='is-size-5 button is-outlined is-primary is-inverted is-uppercase'
							>
								CONTACT US
							</Link>
						</div>
					</div>
				</div>
			</section>

			{/* Tabs Thing */}
			<section className='section' id='tabs'>
				<div className='container'>
					<h2
						data-sal='slide-right'
						data-sal-duration='800'
						data-sal-delay='300'
						data-sal-easing='ease'
						className='has-text-black is-size-2 has-text-weight-bold'
					>
						<br />
						Alliance World Group Companies
					</h2>
					<br />
					<br />

					{tabsInfo.map((currentItem, i) => {
						return (
							<div
								key={currentItem.name}
								data-sal='slide-down'
								data-sal-duration={600}
								data-sal-delay={i * 100}
								data-sal-easing='ease'
								className={css(styles.accordion) + " is-relative accordion"}
							>
								<div className={css(styles.summary) + " summary is-relative"}>
									<h6
										className={
											css(styles.tag) + " has-text-weight-light is-size-4"
										}
									>
										{currentItem.tag}
									</h6>
									<h6
										className={
											css(styles.title) + " is-size-4 has-text-primary"
										}
									>
										{currentItem.name}
									</h6>
									<h6
										className={
											css(styles.desc) + " has-text-weight-light is-size-5"
										}
									>
										{currentItem.desc}
									</h6>
									<span className={css(styles.icon)}>
										<span className={css(styles.icon_design)}>+</span>
									</span>
								</div>
								<input
									type='checkbox'
									className={css(styles.check) + " check"}
								/>
								<div className={css(styles.details) + " details"}>
									<br />
									<br />
									<GridTwo
										left={currentItem.left}
										right={currentItem.right}
									></GridTwo>
									<br />
									<br />
								</div>
							</div>
						)
					})}
				</div>
				<br />
			</section>

			{/* Clients Slider */}
			<section className='section'>
				<div className='container'>
					<h4 className='is-size-2 has-text-weight-bold'> Our Clients </h4>
					<br />
					<Slider {...settings}>
						<div>
							<img
								src={"/clients/1.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/2.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/3.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/4.jpeg"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/5.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/6.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/7.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/8.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/9.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/10.gif"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/11.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/12.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/13.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/14.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/15.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/16.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/17.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/18.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/19.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
						<div>
							<img
								src={"/clients/20.png"}
								style={{
									maxHeight: "120px",
									width: "120px",
									objectFit: "contain",
									margin: "0 auto",
								}}
							></img>
						</div>
					</Slider>
				</div>
			</section>
		</Layout>
	)
}

const styles = StyleSheet.create({
	check: {
		position: "absolute",
		top: "0",
		left: "0",
		width: "100%",
		height: "100%",
		opacity: 0,
		cursor: "pointer",
	},
	accordion: {
		borderBottom: "1px solid #ebebeb",
	},
	summary: {
		display: "flex",
		padding: "1rem",
		flexWrap: "wrap",
		alignItems: "center",
	},
	title: {
		width: "30%",
		"@media (max-width: 768px)": {
			width: "100%",
		},
	},
	tag: {
		width: "20%",
		"@media (max-width: 768px)": {
			width: "100%",
		},
	},
	desc: {
		width: "40%",
		"@media (max-width: 768px)": {
			width: "90%",
		},
	},
	icon: {
		width: "10%",
		"@media (max-width: 768px)": {
			position: "absolute",
			top: "35%",
			right: "20px",
		},
	},
	icon_design: {
		width: "32px",
		height: "32px",
		fontSize: "42px",
		fontWeight: "300",
		borderRadius: "50%",
		display: "flex",
		float: "right",
		color: "#aaa",
		alignItems: "center",
		justifyContent: "center",
		border: "1px solid #aaa",
	},
	headline: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr",
		"@media (max-width: 768px)": {
			gridTemplateColumns: "1fr",
		},
	},
})

export const query = graphql`
	{
		allWpPost(limit: 3, sort: { fields: [date], order: DESC }) {
			nodes {
				date(formatString: "LL")
				title
				excerpt
				slug
				featuredImage {
					node {
						mediaItemUrl
					}
				}
			}
		}
	}
`

export default IndexPage
